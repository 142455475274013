import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';
import NoteView from '@/views/NoteView.vue';
import EditNoteView from '@/views/EditNoteView.vue';
import ChatView from '@/views/ChatView.vue';
import UploadFileView from '@/views/UploadFileView.vue';
import ResourceView from '@/views/ResourceView.vue'
import EditResourceView from '@/views/EditResourceView.vue'
import SettingsView from '@/views/settings/SettingsView.vue'
import UserListView from '@/views/account/UserListView.vue';
import NewUserView from '@/views/account/NewUserView.vue';
import EditUserView from '@/views/account/EditUserView.vue';
import NewGroupView from '@/views/account/NewGroupView.vue';
import EditGroupView from '@/views/account/EditGroupView.vue';
import AdminErrorView from '@/views/account/AdminErrorView.vue';

import ChatListView from '@/views/ChatListView.vue'
import NewChatView from '@/views/chats/NewChatView.vue'
import ChatScreenView from '@/views/chats/ChatScreenView.vue'
import ChatResourceView from '@/views/chats/ChatResourceView.vue'
import ChatResourceUploadView from '@/views/chats/ChatResourceUploadView.vue'
import ChatEditResourceView from '@/views/chats/ChatEditResourceView.vue'
import ChatSettingView from '@/views/chats/ChatSettingView.vue'
import ChatConnectionView from '@/views/chats/ChatConnectionView.vue'
import ChatAdminErrorView from '@/views/ChatAdminErrorView.vue';
import ChatLayout from '@/components/ChatLayout.vue'

import EditPasswordView from '@/views/settings/EditPasswordView.vue'
import UserPlanView from '@/views/settings/UserPlanView.vue'

import SalesListsView from '@/views/forsales/SalesListsView.vue'
import SalesListsDetailsLayout from '@/views/forsales/SalesListsDetailsLayout.vue'
import SalesListsDetailsView from '@/views/forsales/SalesListsDetailsView.vue'
import NewSalesListsDetailsView from '@/views/forsales/NewSalesListsDetailsView.vue'
import SalesListsImportView from '@/views/forsales/SalesListsImportView.vue'
import SalesUsageDetailsView from '@/views/forsales/SalesMonthCountView.vue'

import OptionsErrorView from '@/views/forsales/OptionsErrorView.vue'
import NewSalesListsView from '@/views/forsales/salesLists/NewSalesListsView.vue'
import EditSalesListsView from '@/views/forsales/salesLists/EditSalesListsView.vue'

import sales_templates from "@/views/forsales/sales_templates.vue"
import sales_templates_new from "@/views/forsales/sales_templates_new.vue"
import sales_templates_edit from "@/views/forsales/sales_templates_edit.vue"

const routes = [
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/password',
    name: 'EditPassword',
    component: EditPasswordView,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings/plan',
    name: 'UserPlan',
    component: UserPlanView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/note/:id',
    name: 'Note',
    component: NoteView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/editnote/:id',
    name: 'EditNote',
    component: EditNoteView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: ChatView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/uploadfile',
    name: 'UploadFile',
    component: UploadFileView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/resource',
    name: 'Resource',
    component: ResourceView,
    meta: { requireAuth: true },
  },
  {
    path: '/resource/:resourceId',
    name: 'EditResource',
    component: EditResourceView,
    meta: { requireAuth: true },
    props: true
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { requireAuth: true },
    children: [
      {
        path: '',
        name: 'UserList',
        component: UserListView
      },
      {
        path: 'user/new',
        name: 'NewUser',
        component: NewUserView
      },
      {
        path: 'user/edit/:userId',
        name: 'EditUser',
        component: EditUserView,
        props: true
      },
      {
        path: 'group/new',
        name: 'NewGroup',
        component: NewGroupView
      },
      {
        path: 'group/edit/:groupId',
        name: 'EditGroup',
        component: EditGroupView,
        props: true
      },
      {
        path: 'admin_error',
        name: 'AdminError',
        component: AdminErrorView,
        props: true
      },
    ]
  },
  {
    path: '/chats',
    name: 'Chats',
    meta: { requireAuth: true },
    children: [
      {
        path: '',
        name: 'ChatList',
        component: ChatListView
      },
      {
        path: 'new',
        name: 'NewChat',
        component: NewChatView
      },
      {
        path: ':chat_id',
        component: ChatLayout,
        children: [
          {
            path: '',
            name: 'ChatScreen',
            component: ChatScreenView
          },
          {
            path: 'resource',
            name: 'ChatResource',
            component: ChatResourceView
          },
          {
            path: 'upload',
            name: 'ChatUpload',
            component: ChatResourceUploadView
          },
          {
            path: 'resource/:resourceId',
            name: 'ChatEditResource',
            component: ChatEditResourceView
          },
          {
            path: 'setting',
            name: 'ChatSetting',
            component: ChatSettingView
          },
          {
            path: 'connection',
            name: 'ChatConnection',
            component: ChatConnectionView
          },

        ]
      }
    ]
  },
  {
    path: '/admin_error',
    name: 'ChatAdminError',
    component: ChatAdminErrorView,
  },

  {
    path: '/forsales',
    name: 'ForSales',
    meta: { requireAuth: true },

    children: [
      {
        path: "sales_templates",
        children: [
          {
            path: "",
            name: "sales_templates",
            component: sales_templates
          },
          {
            path: "new",
            name: "sales_templates_new",
            component: sales_templates_new
          },
          {
            path: ":sales_template_id",
            name: "sales_templates_edit",
            component: sales_templates_edit
          },
        ]
      },

      {
        path: 'salesLists',
        // name: 'SalesLists',
        // component: SalesListsView,
        children: [
          {
            path: '',     //forsales/salesLists
            name: 'SalesLists',
            component: SalesListsView,
          },
          {
            path: 'new',  //forsales/salesLists/new
            name: 'NewSalesLists',
            component: NewSalesListsView
          },
          {
            path: 'usage',  //forsales/salesLists/usage
            name: 'SalesListsUsage',
            component: SalesUsageDetailsView
          },
          {
            path: ':sales_list_id/setting',
            name: 'EditSalesLists',
            component: EditSalesListsView
          },
        ]
      },

      {
        path: "sales_lists",
        name: "sales_lists",
        children: [
          {
            path: ":salesListID/sales_lists_details",
            component: SalesListsDetailsLayout,
            children: [
              {
                path: "",
                name: 'SalesListsDetailsView',
                component: SalesListsDetailsView,
              },
              {
                path: 'import',
                name: 'SalesListsImportView',
                component: SalesListsImportView,
              },
              {
                path: 'new',
                name: 'NewSalesListsDetailsView',
                component: NewSalesListsDetailsView,
              },
            ]
          },
        ]
      },

      {
        path: 'options_error',
        name: 'OptionsError',
        component: OptionsErrorView,
        props: true
      },
    ]
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
