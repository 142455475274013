<script setup>
import { onMounted, reactive, computed, ref } from "vue";
import { datePipe } from "@/utils/datatimeUtils";
import { useRouter } from "vue-router";
import { salesListsApi } from "@/api/forsales_index";

const router = useRouter();
const isLoading = ref(false);
const salesListId_delete = ref(-1);
const data = reactive({
  items: []
})

const filterKey = reactive({
  searchKey: null,
});
const searchKey = ref("");

const filteredData = computed(() => {
  let temp = data.items || [];
  if (filterKey.searchKey) {
    temp = temp.filter((row) =>
      Object.keys(row).some(
        (key) =>
          String(row[key]).toLowerCase().indexOf(filterKey.searchKey.toLowerCase()) > -1
      )
    );
  }
  return temp;
});

const saleslistCount = computed(() => {
  return filteredData.value.length;
});

onMounted(async () => {
  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log(optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  }
  catch (error) {
    return router.push(`/forsales/options_error`);
  }

  const resp = await salesListsApi.getAll();
  data.items = resp.data;
  console.log("*** data.items:", data.items)
});

const handleFilter = () => {
  filterKey.searchKey = searchKey.value;
};

const handleEdit_detail = (salesListId) => {
  console.log("handleEdit_detail:", salesListId)
  router.push(`/forsales/sales_lists/${salesListId}/sales_lists_details`);
};

const handleEdit = (salesListId) => {
  console.log("handleEdit:", salesListId)
  router.push(`/forsales/sales_lists/${salesListId}/sales_lists_details`);
};

const handleCreate = () => {
  router.push(`/forsales/salesLists/new`);
};

const handleDelete = async (salesListId) => {
  console.log("*** salesListId:", salesListId)

  salesListId_delete.value = salesListId;
};

const handleDelete_confirm = async () => {
  isLoading.value = true;

  const resp = await salesListsApi.deleteSalesList(salesListId_delete.value);
  if (resp.status !== 200) {
    isLoading.value = false;
    throw new Error("ERROR");
  }

  data.items = data.items.filter((item) => item.id !== salesListId_delete.value);

  isLoading.value = false;
};

</script>

<template>
  <div class="container">
    
    <!--    title-->
    <div class="row">
      <h4>{{ $t('SalesListsView.h4') }}</h4>
    </div>

    <!--    filter-->
    <div class="row">
      <div class="column">

        <label for="search-keyword">{{ $t('SalesListsView.search_keyword') }}</label>
        <input type="text" id="search-keyword" v-model="searchKey" />

        <button class="btn btn-primary" @click="handleFilter" style="padding: 0 50px; margin: 10px;">
          {{ $t("SalesListsDetailsView.handle_filter") }}</button>
      </div>

    </div>

    <!--    counter and new button -->
    <div class="row">

      <div class="column">
        <label for="list-count">{{ $t('SalesListsView.list_count') }}</label>
        <span id="list-count">{{ saleslistCount }}</span>
      </div>

      <div class="column" style="text-align: right">
        <button class="btn btn-primary" @click="handleCreate" style="padding: 0 50px; margin: 10px;">
          {{ $t('SalesListsView.handle_create') }}</button>
      </div>

    </div>

    <!-- リスト一覧テーブル(清單清單表) -->
    <table class="table table-hover">

      <thead>
        <tr>
          <th scope="col">{{ $t('SalesListsView.list_title') }}</th>
          <th scope="col">{{ $t('SalesListsView.author') }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="saleslist in filteredData" :key="saleslist.id">
          <td style="cursor: pointer" @click="handleEdit_detail(saleslist.id)">
            {{ saleslist.list_name }}</td>
          <td>{{ datePipe(saleslist.created_at) }}</td>
          <td>
            <button class="btn btn-primary" style="padding: 0 20px; margin: 10px;" @click="handleEdit(saleslist.id)">
              {{ $t("SalesListsView.edit") }}</button>
            <button class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#delStaticBackdrop"
              @click="handleDelete(saleslist.id)">
              {{ $t('SalesListsView.delete') }}
            </button>
          </td>
        </tr>
      </tbody>

    </table>


  </div>

  <!-- Delete modal -->
  <div class="modal fade" id="delStaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t('SalesListsView.delete') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ $t("SalesListsView.delete_confirm") }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("SalesListsView.cancel") }}
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="handleDelete_confirm">
            {{ $t("SalesListsView.OK") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete modal end -->

</template>

<style scoped>
.row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
}

.table-cell {
  flex: 1;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 40px 0;
}

.table-header .table-cell {
  font-weight: bold;
  background-color: #f5f5f5;
}

.table-row:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
</style>
