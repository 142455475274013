<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { salesListsApi } from "@/api/forsales_index";
import { useRouter } from "vue-router";


const router = useRouter();
const stepNumber = ref(1);
const showImportModal = ref(false);
const showPromptModal = ref(false);

const check_modal_msg = ref("")
const instance = getCurrentInstance()
const prompt_str = ref("")

let sales_templates = []



function set_template_text(template_id) {
  console.log("template_text..." + template_id)
  let saleslist = instance.proxy.saleslist
  for (let i in sales_templates) {
    let id = parseInt(sales_templates[i].id)
    if (id === parseInt(template_id)) {
      saleslist.template_text = sales_templates[i].template_text
    }
  }
}

function formatNewlines(text) {
  if (!text) {
    return '';
  }
  return text.replace(/\\n/g, '<br>');
}

onMounted(async () => {
  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log(optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  }
  catch (error) {
    return router.push(`/forsales/options_error`);
  }

  const resp = await salesListsApi.getAllTemplates()
  const data = resp.data
  console.log("data:", data)

  sales_templates = data

  let saleslist = instance.proxy.saleslist
  set_template_text(saleslist.sales_template_id)
});

const openPromptModal = () => {
  prompt_str.value = instance.proxy.$t("EditSalesListsView.prompt_str")
  showPromptModal.value = true;
};

const closePromptModal = () => {
  showPromptModal.value = false;
};

const openImportModal = () => {
  showImportModal.value = true;
};

const closeImportModal = () => {
  showImportModal.value = false;
};

const handleReturn = () => {
  if (stepNumber.value === 1) {
    router.back();
  } else {
    stepNumber.value--;
  }
};

function isEmpty(val) {
  return (
    val == null || (
      typeof val === "string" &&
      val.trim().length === 0
    )
  )
}

function has_empty_fields() {
  let saleslist = instance.proxy.saleslist
  let t = instance.proxy.$t

  let f_list = ""

  switch (stepNumber.value) {
    case 1: {
      if (isEmpty(saleslist.list_name))
        f_list += t('NewSalesListsView.list_name') + ", "
      if (isEmpty(saleslist.sender_company_name))
        f_list += t('NewSalesListsView.sender_company_name') + ", "
      if (isEmpty(saleslist.sender_name))
        f_list += t('NewSalesListsView.sender_name') + ", "
      if (isEmpty(saleslist.sender_mail_address))
        f_list += t('NewSalesListsView.sender_mail_address') + ", "
      if (isEmpty(saleslist.mail_title))
        f_list += t('NewSalesListsView.mail_title') + ", "
      if (isEmpty(saleslist.to_name))
        f_list += t('NewSalesListsView.to_name') + ", "
      break
    }
    case 2: {
      if (isEmpty(saleslist.service_name))
        f_list += t('NewSalesListsView.service_name') + ", "
      if (isEmpty(saleslist.service_detail_summary))
        f_list += t('NewSalesListsView.service_detail_summary') + ", "
      break
    }
    case 3: {
      if (isEmpty(saleslist.sales_template_id))
        f_list += t('NewSalesListsView.template_name') + ", "
      break
    }
  }

  if (f_list.length > 0) {
    check_modal_msg.value = f_list.substring(0, f_list.length - 2)
      + t('NewSalesListsView.check_modal_msg')
    return true
  }
  return false
}

// 開啟對話框
const openCheckModal = () => {
  let has_empty = has_empty_fields()
  // console.log( "* has_empty", has_empty)

  if (has_empty) {
    // console.log( "** has_empty", has_empty)

    // 顯示警告對話框
    let btn = document.getElementById("showCheckModal");
    btn.click();
  }
  return has_empty
};

const next = () => {
  if (!openCheckModal())
    stepNumber.value++;
};

const handleUpdate = (e) => {
  if (!openCheckModal()) {
    let handleUpdate_2 = instance.proxy.handleUpdate_2
    handleUpdate_2(e)
  }
};


</script>

<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <div>
    <h3>{{ $t('EditSalesListsView.h3') }}</h3>
  </div>

  <!--  3 circle button-->

  <table align="center">
    <!-- three circle of steps -->
    <tbody>
      <tr align="center">
        <td v-show="stepNumber != 1" class="number">1</td>
        <td v-show="stepNumber === 1" class="number" style="background-color: #12f912f3">1</td>
        <td>
          <hr
            style="width:100%;  border-width: 3px; border-color: #12f912f3; text-align:center;vertical-align:middle" />
        </td>
        <td v-show="stepNumber != 2" class="number">2</td>
        <td v-show="stepNumber === 2" class="number" style="background-color: #12f912f3">2</td>
        <td>
          <hr
            style="width:100%;  border-width: 3px; border-color: #12f912f3; text-align:center;vertical-align:middle" />
        </td>
        <td v-show="stepNumber === 3" class="number" style="background-color: #12f912f3">3</td>
        <td v-show="stepNumber != 3" class="number">3</td>
      </tr>
      <tr>
        <td>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('EditSalesListsView.title_base')
          }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td style="width: 40px;"></td>
        <td>{{ $t('EditSalesListsView.title_service') }}</td>
        <td style="width: 40px;"></td>
        <td>{{ $t('EditSalesListsView.title_template') }}</td>
      </tr>
    </tbody>
  </table>

  <!-------- step:1 -------->

  <div v-if="stepNumber === 1">

    <label for="list_name" class="form-label required">
      {{ $t('EditSalesListsView.list_name') }}</label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="list_name" v-model="saleslist.list_name">
    </div>

    <label for="sender_company_name" class="form-label required">
      {{ $t('EditSalesListsView.sender_company_name') }}</label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="sender_company_name" v-model="saleslist.sender_company_name">
    </div>

    <label for="sender_name" class="form-label required">
      {{ $t('EditSalesListsView.sender_name') }}</label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="sender_name" v-model="saleslist.sender_name">
    </div>

    <label for="sender_mail_address" class="form-label required">
      {{ $t('EditSalesListsView.sender_mail_address') }}</label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="sender_mail_address" v-model="saleslist.sender_mail_address">
    </div>

    <label for="mail_title" class="form-label required">
      {{ $t('EditSalesListsView.mail_title') }}</label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="mail_title" v-model="saleslist.mail_title">
    </div>

    <label for="to_name" class="form-label required">
      {{ $t('EditSalesListsView.to_name') }} </label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="to_name" v-model="saleslist.to_name">
    </div>

    <label for="to_name" class="form-label">
      {{ $t('EditSalesListsView.bcc') }} </label>
    <div class="input-group mb-3">
      <input type="email" class="form-control rounded" placeholder="name@example.com" id="bcc">
    </div>

    <label for="file-upload" class="form-label">
      {{ $t('NewSalesListsView.file_upload') + saleslist.file_name }}
      <button v-if="saleslist.file_name != ''" type="button" class="btn-close file-del-btn ps-3" aria-label="Close"
        data-bs-toggle="modal" data-bs-target="#mailFileDeleteModal"></button>
    </label>
    <div class="form-group mb-3">
      <input class="form-control" type="file" accept="application/pdf" ref="fileInput" @change="onFileChangeMail"
        id="formFile">
    </div>
    <div class="text-danger">
      {{ $t('NewSalesListsView.must_pdf') }}
    </div>

    <br />
    <br />
  </div>

  <!-------- step:2 -------->

  <div v-if="stepNumber === 2">

    <label for="service_name" class="form-label required">
      {{ $t('EditSalesListsView.service_name') }}</label>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="service_name" v-model="saleslist.service_name">
    </div>

    <table class="table table-hover">
      <tr>
        <td>
          <label for="service_detail_summary" class="form-label required">
            {{ $t('EditSalesListsView.service_detail_summary') }} </label>
        </td>
        <td>
          <!-- Button for import -->
          <button class="btn btn-import" type="button" @click="openImportModal">
            {{ $t('EditSalesListsView.import') }}</button>
        </td>
      </tr>
    </table>

    <div class="input-group mb-3">
      <textarea class="form-control" id="service_detail_summary" v-model="saleslist.service_detail_summary"
        rows="7"></textarea>
    </div>

    <br />
    <br />
  </div>

  <!-------- step:3 -------->

  <div v-if="stepNumber === 3">

    <!--  template_id-->
    <div class="form-group">
      <label for="template_id" class="form-label required">
        {{ $t('EditSalesListsView.template_name') }}</label>
      <select id="template_id" class="form-input" @change="set_template_text($event.target.value)"
        v-model="saleslist.sales_template_id" style="width: 300px">
        <option value="">-</option>
        <option v-for="(item) in sales_templates" :value="item.id" :key="item.id">
          {{ item.template_name }}</option>
      </select>
    </div>

    <!--  template_text-->
    <div class="input-group mb-3">
      <div class="form-control" id="template_text" v-html="formatNewlines(saleslist.template_text)"
        style="white-space: pre-wrap;"></div>
    </div>
    <br />

    <!--  additional_prompt-->
    <label for="additional_prompt" class="form-label prompt" @click="openPromptModal">
      {{ $t('EditSalesListsView.additional_prompt') }}
    </label>
    <div class="input-group mb-3">
      <textarea class="form-control" id="additional_prompt" rows="7" v-model="saleslist.additional_prompt"></textarea>
    </div>

    <br />
  </div>

  <!-------- step:buttons -------->

  <div class="d-grid gap-2 d-md-flex justify-content-md-end">

    <button class="btn btn-primary" type="button" @click="handleReturn">
      {{ $t('EditSalesListsView.return') }}</button>

    <button class="btn btn-primary" v-if="stepNumber < 3" type="button" @click="next">
      {{ $t('EditSalesListsView.next') }}</button>

    <button class="btn btn-primary" v-else type="button" @click="handleUpdate($event)">
      {{ $t('EditSalesListsView.update') }}</button>
  </div>

  <!-- prompt modal begin-->
  <div v-if="showPromptModal">
    <div class="d-modal-content flex-column">
      <p v-html=prompt_str></p>
      <button class="btn btn-primary" @click="closePromptModal">
        {{ $t("EditSalesListsView.check_modal_close") }}
      </button>
    </div>
  </div>
  <!-- prompt modal end-->

  <!-- Import modal begin -->
  <div v-if="showImportModal" id="ImportModal" class="modal-import">
    <div class="d-modal-content flex-column">
      
      <!-- 閉じるボタン -->
      <div class="d-flex justify-content-end">
        <button type="button" class="btn-close" aria-label="Close" @click="closeImportModal"></button>
      </div>

      <!-- 閉じるボタン -->
      <div class="d-flex justify-content-end">
        <button type="button" class="btn-close" aria-label="Close" @click="closeImportModal"></button>
      </div>

      <!-- select menu-->
      <div class="form-group">
        <label for="resource-type" class="form-label">
          {{ $t('ChatResourceUploadView.resource_type') }}</label>
        <select v-model="resourceType" id="resource-type" class="form-input">
          <option value="file">{{ $t('ChatResourceUploadView.file') }}</option>
          <option value="web">Web</option>
        </select>
      </div>

      <!-- file selected -->
      <div v-if="resourceType === 'file'">
        <div class="form-group">
          <label for="file-upload" class="form-label required">
            {{ $t('ChatResourceUploadView.choose_file') }}</label>
          <input id="file-upload" type="file" @change="onFileChange" class="form-input" />
        </div>
        <button @click="uploadFile" class="upload-button rounded btn btn-primary">
          {{ $t('ChatResourceUploadView.upload') }}
        </button>
      </div>

      <!-- web selected -->
      <div v-if="resourceType === 'web'">
        <div class="form-group">
          <label for="document-url" class="form-label required">URL</label>
          <input id="document-url" type="text" v-model="document_url" placeholder="https://vector-flux.com/"
            class="form-input" />
        </div>
        <button @click="uploadWebResource" class="upload-button rounded btn btn-primary">
          {{ $t('ChatResourceUploadView.uploadWebResource') }}
        </button>
      </div>

      <p v-if="responseMessage" class="response-message">{{ responseMessage }}</p>

      <!-- hided button for close form -->
      <button id="btnClose" class="w-100 btn btn-secondary mt-3" style="height: 48px;" @click="closeImportModal">
        {{ $t("EditSalesListsView.check_modal_close") }}
      </button>
    </div>
  </div>
  <!-- Import modal end -->

  <!-- Check modal begin -->
  <button id="showCheckModal" data-bs-toggle="modal" data-bs-target="#CheckModal" style="display:none;"></button>
  <div class="modal fade" id="CheckModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t('EditSalesListsView.check_modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ check_modal_msg }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            {{ $t('EditSalesListsView.check_modal_close') }}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Check modal end -->

  <!-- Mail File Delete modal begin -->
  <div class="modal fade" id="mailFileDeleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("EditSalesListsView.delete_title") }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ $t("EditSalesListsView.delete_confirm") }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteFileMail">
            {{ $t("EditSalesListsView.OK") }}
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("EditSalesListsView.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Mail File Delete modal end -->

</template>

<script>
import { upsertFileApi, upsertScrapeApi } from "@/api";
import { useRoute } from "vue-router";
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';

export default {

  data() {
    return {

      saleslist: {
        id: -1,

        // step 1
        list_name: "",
        sender_company_name: "",
        sender_name: "",
        sender_mail_address: "",
        mail_title: "",
        to_name: "",
        bcc: [],
        file_name: "",

        // step 2
        service_name: "",
        service_detail_summary: "",

        // step 3
        sales_template_id: null,
        template_name: "",
        template_text: "",
        additional_prompt: ""
      },

      selectedFile: null,
      responseMessage: "",
      document_title: "",
      document_url: "",
      file_name: "",
      tags: "",
      resourceType: "file",
      selectedFileMail: null,
      isLoading: false,
      tags_format: this.$t('ChatResourceUploadView.tags_format'),
      input_file_name: this.$t('ChatResourceUploadView.file_name'),
      page_name: this.$t('ChatResourceUploadView.page_name'),
      bcc_tagify: null,
      raw_file_name: "",
    };
  },

  mounted() {
    // saleslistデータの取得
    this.getSalesList();
  },

  beforeUnmount() {
    // コンポーネントの破棄時にWebSocket接続を閉じる
    if (this.ws) {  // <-- この条件を追加
      this.ws.close();
    }
  },

  methods:
  {
    async getSalesList() {
      const route = useRoute();
      //const router = useRouter()

      const sales_list_id = route.params.sales_list_id;
      console.log("** sales_list_id:", sales_list_id);

      // todo: check the resp
      const resp = await salesListsApi.getList_1(sales_list_id);
      const data = resp.data;
      console.log("data:", data);

      this.saleslist.id = sales_list_id;

      this.saleslist.list_name = data.list_name;
      this.saleslist.sender_company_name = data.sender_company_name;
      this.saleslist.sender_name = data.sender_name;
      this.saleslist.sender_mail_address = data.sender_mail_address;
      this.saleslist.mail_title = data.mail_title;
      this.saleslist.to_name = data.to_name;
      this.saleslist.bcc = data.bcc;
      this.saleslist.file_name = data.file_path == undefined ? "" : data.file_path;
      this.raw_file_name = data.file_path == undefined ? "" : data.file_path;
      if (this.saleslist.file_name != "") {
        this.saleslist.file_name = this.saleslist.file_name.split("$$")[1]
      }


      this.saleslist.service_name = data.service_name;
      this.saleslist.service_detail_summary = data.service_detail_summary;

      this.saleslist.sales_template_id = data.sales_template_id;
      this.saleslist.template_name = data.template_name;
      this.saleslist.template_text = data.template_text;
      this.saleslist.additional_prompt = data.additional_prompt;
      this.saleslist.file_path = data.file_path;

      console.log("*** sales_template_id:", this.saleslist.sales_template_id);


      // インプットタグの初期化
      this.intTagify();

      return this.$store.dispatch("viewMe");
    },

    intTagify() {
      // インプットタグの初期化
      const bcc_input = document.querySelector('#bcc');
      this.bcc_tagify = new Tagify(bcc_input);
      this.bcc_tagify.addTags(this.saleslist.bcc);
    },

    async handleUpdate_2() {

      console.log("id:", this.saleslist.id)

      this.isLoading = true;
      const blob_name = await this.uploadFileMail();
      const _dict = {
        list_name: this.saleslist.list_name,
        sender_company_name: this.saleslist.sender_company_name,
        sender_name: this.saleslist.sender_name,
        sender_mail_address: this.saleslist.sender_mail_address,
        mail_title: this.saleslist.mail_title,
        to_name: this.saleslist.to_name,
        bcc: this.bcc_tagify.value.map(item => item['value']),
        file_path: blob_name !== undefined ? blob_name : this.saleslist.file_path,
        sales_template_id: this.saleslist.sales_template_id,
        service_name: this.saleslist.service_name,
        service_detail_summary: this.saleslist.service_detail_summary,
        additional_prompt: this.saleslist.additional_prompt,
      }
      console.log("_dict: ", _dict)

      await salesListsApi.updateSaleslist(this.saleslist.id, _dict)
      this.isLoading = false;

      this.$router.push("/forsales/salesLists")
    },

    onFileChange(e) {
      this.selectedFile = e.target.files[0];
    },

    async createWebSocketConnection() {
      const wsId = Math.random().toString(32).substring(2)
      const ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`);

      ws.onmessage = (event) => {
        (async () => {
          try {
            console.log(event);

            const wsResponseData = JSON.parse(event.data);
            // console.log(wsResponseData);

            if (wsResponseData.error) {
              // this.isLoading = false;
              // this.showModal = true;
              // this.show_messages = false;
            }
            else {
              // *** the main process of return value ***
              await this.processResponse(wsResponseData);
            }
          }
          catch (error) {
            console.error('Error processing WebSocket message:', error);

            // 必要に応じてエラーハンドリングをここに追加
            // (如果需要，請在此處新增錯誤處理)

            // this.isLoading = false;
            // this.showModal = true;
            // this.show_messages = false;
          }
          finally {
            ws.close();
          }
        })();
      };

      return wsId;
    },

    onFileChangeMail(e) {
      this.selectedFileMail = e.target.files[0];
      console.log("e.target.files[0]: ", this.selectedFileMail);
      // アップデートファイルのファイルタイプ確認
      if (this.selectedFileMail) {
        let file_type = this.selectedFileMail.name.split('.').pop().toLowerCase();
        if (file_type != "pdf") {
          alert(this.$t('NewSalesListsView.not_pdf'));
          this.selectedFileMail = undefined;
          this.$refs.fileInput.value = "";
          return;
        }
      }
    },

    async processResponse(responseData) {
      console.log("***** responseData:", responseData);

      this.saleslist.service_name = responseData["service_name"]
      this.saleslist.service_detail_summary = responseData["service_detail"]

      // showImportModal.value = false;
      const btnClose = document.getElementById("btnClose");
      btnClose.click();
    },

    async uploadFile() {
      if (!this.selectedFile) {
        alert(this.$t('ChatResourceUploadView.no_files_selected'));
        return;
      }

      try {
        // WebSocketの生成
        const wsId = await this.createWebSocketConnection();

        //this.responseMessage = "Processing...";
        const formData = new FormData();

        formData.append("file", this.selectedFile);

        const metadata = {
          tags: this.tags.split(",").map((tag) => tag.trim()),
          file_name: this.file_name,
          document_url: this.document_url,
          locale: this.$i18n.locale
        };
        formData.append("metadata", JSON.stringify(metadata));

        const response = await upsertFileApi.upload_service(formData, wsId);
        if (response.status != 200) {
          throw new Error("ERROR");
        }

        const responseData = await response.data;
        this.responseMessage = responseData.message;
        console.log("responseMessage(file):", this.responseMessage)
      }
      catch (error) {
        console.error(error);
        this.responseMessage = "Upload failed.";
      }

    },

    async uploadFileMail() {
      if (!this.selectedFileMail) {
        return;
      }

      try {
        const formData = new FormData();
        formData.append("file", this.selectedFileMail);
        const response = await upsertFileApi.upload_service_mail(formData);
        if (response.status != 200) {
          throw new Error("ERROR");
        }

        const responseData = await response.data;
        console.log("blob_name: ", responseData.blob_name);
        return responseData.blob_name;
      }
      catch (error) {
        console.error(error);
        this.responseMessage = "Upload failed.";
      }
    },

    async deleteFileMail() {
      if (this.raw_file_name == "") {
        return;
      }

      try {
        await salesListsApi.deleteMailFile(this.raw_file_name);
        this.saleslist.file_name = "";
        console.log("delete_blob_name: ", this.raw_file_name);
      }
      catch (error) {
        console.error(error);
      }
    },

    async uploadWebResource() {
      try {
        // WebSocketの生成
        const wsId = await this.createWebSocketConnection();

        //this.responseMessage = "アップロード中...";
        const formData = new FormData();

        formData.append("url", this.document_url);

        const metadata = {
          tags: this.tags.split(",").map((tag) => tag.trim()),
          title: this.document_title,
          document_url: this.document_url,
          locale: this.$i18n.locale
        };
        formData.append("metadata", JSON.stringify(metadata));

        const response = await upsertScrapeApi.upload_service(formData, wsId);
        if (response.status != 200) {
          throw new Error("ERROR");
        }

        const responseData = await response.data;
        this.responseMessage = responseData.message;
        console.log("responseMessage(web):", this.responseMessage)
      }
      catch (error) {
        console.error(error);
        this.responseMessage = "Upload failed.";
      }
    },

  },

};
</script>

<style scoped>
.required::after {
  content: "必須";
  color: #ffffff;
  background: #cc0000;

  font-size: 0.8em;
  padding: 0.3em;
  border-radius: 0.5em;
  margin-left: 0.3em;
}

.prompt::after {
  content: "?";
  color: #ffffff;
  background: #2B66FF;

  font-size: 0.8em;
  padding: 0.4em;
  border-radius: 0.8em;
  margin-left: 0.8em;
}

label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-new {
  margin-right: 50px;
}

.btn-import {
  /* margin-left: 50px; */
  /* width: 468px;
  height: 67px; */
  border-radius: 13px;
  background: rgba(244, 126, 118, 1);
}

.number {
  display: inline-table;
  border-radius: 30px;
  background-color: gray;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  /* vertical-align: middle; */
  color: #fff;
  margin: 0;
}

.d-modal-content {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  /* お好みに合わせて変更 */
  max-width: 500px;
  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-center {
  justify-content: center;
  align-items: center;
}

.modal-import {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  /* 他の要素より前面に */
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.file-del-btn {
  height: 9px;
  width: 9px;
}
</style>
