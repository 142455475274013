<script setup>
import { onMounted, reactive, computed, ref } from "vue";
import { salesListsApi } from "@/api/forsales_index";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { regenerationRequest } from "@/store/modules/regenerationSalesListsDetail";

const router = useRouter();
const route = useRoute();
const pageMaxCount = 100;
const { locale } = useI18n();
const raw_data = ref([]);
const salesListID = route.params.salesListID;
console.log("salesListID:", salesListID)
const data = reactive({
  items: [],
  checked_items: {},
  filtered_Key: {
    sales_list_id: salesListID,
    search_Key: null,
    target_flag: null,
    crawl_status: null,
    generate_status: null,
    check_status: null,
    send_status: null,
  }
});
const filterKey = reactive({
  searchKey: null,
});
const searchKey = ref("");
const pageList = ref([]);
const pageIndex = ref(0);
const selectedPage = ref(1);
const isLoading = ref(false);
const id_for_delete = ref(-1);
const title_for_delete = ref("");
let lastPage = 0;
const salesListDetailId = ref(0);
const detai_item = reactive({
  company_name: "",
  company_url: "",
  email: "",
  company_detail: "",
  mail_text: "",

  crawl_status: 0,
  generate_status: 0,
  check_status: false,
  send_status: 0,
});
const all_select_flg = ref(false);

const data_count = computed(() => { return raw_data.value.length });

const showPaging = computed(() => {
  if (data.items.length > 0) {
    return true;
  } else {
    return false;
  }
});

const activePageList = computed(() => { return pageList.value[pageIndex.value] });

const crawl_val = [
  "SalesListsDetailsView.process_pending",
  "SalesListsDetailsView.processing",
  "SalesListsDetailsView.finish",
  "SalesListsDetailsView.process_fail",
]

const generate_val = [
  "SalesListsDetailsView.generate_pending",
  "SalesListsDetailsView.generating",
  "SalesListsDetailsView.finish",
  "SalesListsDetailsView.generate_fail",
]

const check_val = [
  "SalesListsDetailsView.uncheck",
  "SalesListsDetailsView.checked",
]

const send_val = [
  "SalesListsDetailsView.send_none",
  "SalesListsDetailsView.send_pending",
  "SalesListsDetailsView.send_processing",
  "SalesListsDetailsView.send_finish",
  "SalesListsDetailsView.send_fail",
  "SalesListsDetailsView.send_delivered",
  "SalesListsDetailsView.send_open",
  "SalesListsDetailsView.send_click",
]

// hook for life(生命周期钩子)
onMounted(async () => {

  try {
    const optionResp = await salesListsApi.check_optionAccess();
    console.log(optionResp)
    if (optionResp.message !== "ok") {
      return router.push(`/forsales/options_error`);
    }
  }
  catch (error) {
    return router.push(`/forsales/options_error`);
  }

  getAllData();
});

const getAllData = async () => {
  console.log("salesListID:", salesListID)
  const resp = await salesListsApi.getAllDetailsFilter(salesListID);
  raw_data.value = resp.data;
  data.items = getPageData(resp.data, true);
  console.log("data.items:", data.items)
  raw_data.value.map((item) => {
    data.checked_items[item.id] = false;
  });
  console.log("checked_items:", data.checked_items)

  pageList.value = await salesListsApi.getSalesDetailPage(salesListID);
  getLastPage();
}

const handleFilter = async () => {
  isLoading.value = true;
  setFirstPage();
  filterKey.searchKey = searchKey.value;

  // 検索キーが全部NULLの場合はデータは変更しない
  let is_key_null = checke_filter_key_null();
  if (is_key_null) {
    getAllData();
    return
  }

  // 検索キーでデータの取得
  raw_data.value = await salesListsApi.getDetailsFilterByKey(data.filtered_Key);
  data.items = getPageData(raw_data.value, false);
  data.checked_items = {};
  raw_data.value.map((item) => { data.checked_items[item.id] = false; });
  isLoading.value = false;
};

const handleCreate = async () => {
  console.log("handleCreate()...")
  console.log("salesListID:", salesListID)
  await router.push(`/forsales/sales_lists/${salesListID}/sales_lists_details/new`);
};

const handleGenerate = async () => {
  isLoading.value = true;
  console.log("handle_generate()...")
  console.log("checked_items:", data.checked_items)
  console.log("currentRoute:", router.currentRoute.value)
  const items = raw_data.value;
  for (const i in items) {
    const detail_id = items[i].id
    if (data.checked_items[detail_id]) {
      const company_url = items[i].company_url
      console.log("** ", i, detail_id, company_url)
      await updateTargetFlag(detail_id, true, company_url)
    }
  }
  isLoading.value = false;
  refreshData();
}


const handleSendMail = async () => {
  console.log("handle_send_mail()...")

  const sales_list_id = salesListID
  console.log("sales_list_id:", sales_list_id)
  const resp = await salesListsApi.getList_1(sales_list_id)
  const sales_list = resp.data
  const sender_mail_address = sales_list.sender_mail_address
  const mail_title = sales_list.mail_title

  isLoading.value = true

  const detail = raw_data.value;
  for (const i in detail) {
    const detail_id = detail[i].id
    if (data.checked_items[detail_id]) {
      const check_status = detail[i].check_status
      if (check_status !== 1)
        continue

      // const crawl_status = detail[i].crawl_status
      // if (crawl_status == 1 || crawl_status == 0)
      //   continue

      // const generate_status = detail[i].generate_status
      // if (generate_status == 1 || generate_status == 0)
      //   continue

      const send_status = detail[i].send_status
      if (send_status === 0) {
        // update send_status to 2
        let _dict = {
          sales_lists_detail_id: detail_id,
          send_status: 2,
        }
        let resp_update = await salesListsApi.updateSaleslist_detail(_dict);
        console.log("** resp_update:", resp_update)
        if (resp_update.status !== 200) {
          throw new Error("update send_status to 1 ERROR");
        }

        // try to send mail
        const email = detail[i].email
        const mail_text = detail[i].mail_text


        // send mail
        const formData = new FormData()
        // formData.append( "detail_id", id);
        formData.append("sender_mail_address", sender_mail_address);
        formData.append("mail_title", mail_title);
        formData.append("email", email);
        formData.append("mail_text", mail_text);
        formData.append("sales_list_id", salesListID);
        console.log("formData:", formData)

        const rsp = await salesListsApi.send_mail(detail_id, formData);
        if (rsp.status !== 200) {
          throw new Error("ERROR");
        }

      }

    }
  }
  isLoading.value = false

  // 顯示警告對話框
  let btn = document.getElementById("send_mail_finished");
  btn.click();

};

const onSelectAllChange = () => {
  for (const item of data.items) {
    const id = item.id;
    data.checked_items[id] = all_select_flg.value;
  }
  console.log("checked_items:", data.checked_items)
}

const onSelectChange = async (e) => {
  console.log(e.target.value, e.target.checked)
  console.log(data.checked_items)
};

const updateTargetFlag = async (detail_id, target_flag, company_url) => {
  console.log("update_target_flag:", detail_id, target_flag)
  if (target_flag) {
    // call api
    const formData = new FormData()
    formData.append("target_flag", target_flag)
    formData.append("locale", locale.value)
    formData.append("company_url", company_url)
    const resp = await salesListsApi.update_target_flag(detail_id, formData)
    if (resp.status !== 200) {
      throw new Error("salesListsApi.update_target_flag() ERROR");
    }
    console.log("update_target_flag.resp:", resp)
  }
}

const handleEdit = async (detailId) => {

  console.log("** handleEdit:salesListDetailId:", detailId);

  salesListDetailId.value = detailId;

  const resp = await salesListsApi.getDetails_1(detailId);
  detai_item.company_name = resp.data.company_name;
  detai_item.company_url = resp.data.company_url;
  detai_item.email = resp.data.email;
  detai_item.company_detail = resp.data.company_detail;
  detai_item.mail_text = resp.data.mail_text;
  detai_item.crawl_status = resp.data.crawl_status;
  detai_item.generate_status = resp.data.generate_status;
  detai_item.check_status = Boolean(resp.data.check_status);
  detai_item.send_status = resp.data.send_status
};

const openDeleteModal = (item) => {
  id_for_delete.value = item.id;
  title_for_delete.value = item.company_name;
};

const handleDelete = async () => {
  isLoading.value = true;

  const resp = await salesListsApi.deleteSalesListDetail(
    id_for_delete.value, { salesListID: salesListID });
  if (resp.status !== 200) {
    isLoading.value = false;
    throw new Error("ERROR");
  }
  data.items = data.items.filter((item) => item.id !== id_for_delete.value);
  isLoading.value = false;
};

const refreshData = async () => {
  isLoading.value = true;
  const filter_key_null = checke_filter_key_null();
  if (filter_key_null) {
    const resp = await salesListsApi.getAllDetailsFilter(salesListID);
    raw_data.value = resp.data;
  } else {
    raw_data.value = await salesListsApi.getDetailsFilterByKey(data.filtered_Key);
  }
  data.items = getPageData(raw_data.value, false);
  isLoading.value = false;
}

const setSelectedPage = (page) => {
  all_select_flg.value = false;
  selectedPage.value = page;
  data.items = getPageData(raw_data.value, false);
}

const setFirstPage = () => {
  all_select_flg.value = false;
  selectedPage.value = 1;
  pageIndex.value = 0;
  data.items = getPageData(raw_data.value, false);
}

const setLastPage = () => {
  all_select_flg.value = false;
  selectedPage.value = lastPage;
  pageIndex.value = pageList.value.length - 1;
  data.items = getPageData(raw_data.value, false);
}

const nextPage = () => {
  // 最後のページは何もしない
  if (selectedPage.value == lastPage) {
    return;
  }
  all_select_flg.value = false;
  selectedPage.value += 1;
  if (!(activePageList.value.includes(selectedPage.value))) {
    pageIndex.value += 1;
  }

  data.items = getPageData(raw_data.value, false);
}

const previousPage = () => {
  // 最初のページは何もしない
  if (selectedPage.value == 1) {
    return;
  }
  all_select_flg.value = false;
  selectedPage.value -= 1;
  if (!(activePageList.value.includes(selectedPage.value))) {
    pageIndex.value -= 1;
  }

  data.items = getPageData(raw_data.value, false);
}

const makePageList = (totalPages) => {
  const array = Array.from({ length: totalPages }, (v, i) => i + 1);
  const groupSize = 6;
  const result = [];

  for (let i = 0; i < array.length; i += groupSize) {
    result.push(array.slice(i, i + groupSize));
  }
  pageList.value = result;
}

const getLastPage = () => {
  if (pageList.value.length != 0) {
    let lastIndex = pageList.value[pageList.value.length - 1];
    lastPage = lastIndex[lastIndex.length - 1];
  }
}

const regeneration = async () => {
  const regeneration_request = new regenerationRequest(salesListID, locale.value);
  const items = raw_data.value;
  for (const i in items) {
    const detail_id = items[i].id;
    if (data.checked_items[detail_id]) {
      regeneration_request.sales_list_detail_ids.push(detail_id)
    }
  }
  await salesListsApi.regenerationSalesListsDetail(regeneration_request);
  refreshData();

}

const updateData = async () => {
  const _dict = {
    sales_lists_detail_id: salesListDetailId.value,
    company_name: detai_item.company_name,
    company_url: detai_item.company_url,
    email: detai_item.email,
    company_detail: detai_item.company_detail,
    mail_text: detai_item.mail_text,
    check_status: Number(detai_item.check_status),
  }

  isLoading.value = true;
  await salesListsApi.updateSaleslist_detail(_dict);
  refreshData();

  data.items = data.items.map((item) => {
    if (item.id == salesListDetailId.value) {
      item.check_status = Number(detai_item.check_status);
    }
    return item
  });

  isLoading.value = false;
}

const checke_filter_key_null = () => {
  if (data.filtered_Key.check_status === null &&
    data.filtered_Key.crawl_status === null &&
    data.filtered_Key.generate_status === null &&
    data.filtered_Key.search_Key === null &&
    data.filtered_Key.send_status === null &&
    data.filtered_Key.target_flag === null
  ) {
    return true
  }

  return false
}

const getPageData = (data, is_onMounted) => {
  if (!is_onMounted) {
    // ページ変数を更新する
    const totalPages = Math.ceil(data.length / pageMaxCount);
    makePageList(totalPages);
    getLastPage();
  }

  let start = (selectedPage.value - 1) * pageMaxCount;
  let end = selectedPage.value * pageMaxCount;
  return data.slice(start, end);
}
</script>

<template>
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>

  <!-- main view -->
  <div class="container">

    <!-- title(標題) -->
    <div class="row">
      <h4>{{ $t("SalesListsDetailsView.h4") }}</h4>
    </div>

    <!-- key for filer(關鍵詞過濾) -->
    <div class="row">
      <div class="column">

        <label for="search-keyword">{{ $t("SalesListsDetailsView.search_keyword") }}</label>
        <input type="text" id="search-keyword" v-model="data.filtered_Key.search_Key"
          style="padding: 0 50px; margin: 10px;" />

        <button class="btn btn-primary" @click="handleFilter" style="padding: 0 50px; margin: 10px;">
          {{ $t("SalesListsDetailsView.handle_filter") }}</button>
      </div>

    </div>

    <!-- 4 combobox(4個下拉選項) -->
    <div class="row">

      <!-- info of target_flag(生成対象) -->
      <div class="column">
        <label for="target_flag">{{ $t("SalesListsDetailsView.target_flag") }}</label>
        <select id="target_flag" class="form-select" v-model="data.filtered_Key.target_flag" @change="handleFilter">
          <option :value=null> - </option>
          <option :value=true>ON</option>
          <option :value=false>OFF</option>
        </select>
      </div>

      <!-- info of company(獲取公司資訊) -->
      <div class="column">
        <label for="company_information">
          {{ $t("SalesListsDetailsView.company_information") }}</label>
        <select id="company_information" class="form-select" v-model="data.filtered_Key.crawl_status"
          @change="handleFilter">
          <option :value=null> - </option>
          <option :value=0>{{ $t(crawl_val[0]) }}</option>
          <option :value=1>{{ $t(crawl_val[1]) }}</option>
          <option :value=2>{{ $t(crawl_val[2]) }}</option>
          <option :value=3>{{ $t(crawl_val[3]) }}</option>
        </select>
      </div>

      <!-- text generate(文章生成) -->
      <div class="column">
        <label for="text_generate">
          {{ $t("SalesListsDetailsView.text_generate") }}</label>
        <select id="text_generate" class="form-select" v-model="data.filtered_Key.generate_status"
          @change="handleFilter">
          <option :value=null> - </option>
          <option :value=0>{{ $t(generate_val[0]) }}</option>
          <option :value=1>{{ $t(generate_val[1]) }}</option>
          <option :value=2>{{ $t(generate_val[2]) }}</option>
          <option :value=3>{{ $t(generate_val[3]) }}</option>
        </select>
      </div>

      <!-- text confirm(内容確認) -->
      <div class="column">
        <label for="text_confirm">
          {{ $t("SalesListsDetailsView.text_confirm") }}</label>
        <select id="text_confirm" class="form-select" v-model="data.filtered_Key.check_status" @change="handleFilter">
          <option :value=null> - </option>
          <option :value=0>{{ $t(check_val[0]) }}</option>
          <option :value=1>{{ $t(check_val[1]) }}</option>
        </select>
      </div>

      <!-- send mail(發電子郵件) -->
      <div class="column">
        <label for="send_mail">{{ $t("SalesListsDetailsView.send_mail") }}</label>
        <select id="send_mail" class="form-select" v-model="data.filtered_Key.send_status" @click="handleFilter">
          <option :value=null> - </option>
          <option :value=0>{{ $t(send_val[0]) }}</option>
          <option :value=1>{{ $t(send_val[1]) }}</option>
          <option :value=2>{{ $t(send_val[2]) }}</option>
          <option :value=3>{{ $t(send_val[3]) }}</option>
          <option :value=4>{{ $t(send_val[4]) }}</option>
        </select>
      </div>
    </div>

    <div class="d-flex justify-content-between">

      <!-- select/de-select -->
      <div>
        <input class="form-check-input" id="select_all" type="checkbox" v-model="all_select_flg"
          @change="onSelectAllChange" />
        <label style="padding: 0 50px; margin: 1px;" for="select_all">
          {{ $t("SalesListsDetailsView.select_all") }}</label>
      </div>

      <!-- 2 button -->
      <div>

        <button class="btn btn-primary" @click="handleCreate" style="padding: 0 50px; margin: 10px;">
          {{ $t("SalesListsDetailsView.handleCreate") }}</button>

        <button class="btn btn-primary" @click="handleGenerate" style="padding: 0 50px; margin:10px;">
          {{ $t("SalesListsDetailsView.target_flag") }}</button>

        <button class="btn btn-primary" @click="regeneration" style="padding: 0 50px; margin:10px;">
          {{ $t("SalesListsDetailsView.regeneration") }}</button>

        <button class="btn btn-primary" @click="handleSendMail" style="padding: 0 50px; margin: 10px;">
          {{ $t("SalesListsDetailsView.handle_send") }}</button>

        <button class="btn btn-primary" @click="refreshData" style="padding: 0 50px; margin: 10px;">
          {{ $t("SalesListsDetailsView.refresh") }}
        </button>

      </div>
    </div>

    <!-- データ件数表示 -->
    <div>{{ $t("SalesListsDetailsView.data_count") + data_count }}</div>

    <!-- the main table(主體表格) -->
    <div class="row">
      <table class="table table-hover table-sm fixed-table">
        <thead>
          <tr>
            <th scope="col" style="width: 50px;">
              {{ $t("SalesListsDetailsView.selected") }}</th>
            <th scope="col">
              {{ $t("SalesListsDetailsView.company_name") }}</th>
            <th scope="col" style="width: 190px;">
              {{ $t("SalesListsDetailsView.company_url") }}</th>
            <th scope="col" style="width: 90px;">
              {{ $t("SalesListsDetailsView.target") }}</th>
            <th scope="col" style="width: 120px;">
              {{ $t("SalesListsDetailsView.crawl_status") }}</th>
            <th scope="col" style="width: 100px;">
              {{ $t("SalesListsDetailsView.generate_status") }}</th>
            <th scope="col" style="width: 100px;">
              {{ $t("SalesListsDetailsView.check_status") }}</th>
            <th scope="col" style="width: 100px;">
              {{ $t("SalesListsDetailsView.send_status") }}</th>
            <th scope="col" style="width: 250px; text-align: center;">
              {{ $t("SalesListsDetailsView.operation") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in data.items" :key="item.id">

            <td style="width: 50px;">
              <input class="form-check-input" type="checkbox" v-model="data.checked_items[item.id]"
                @change="onSelectChange">
            </td>

            <td>{{ item.company_name }}</td>
            <td style="width: 190px;">{{ item.company_url }}</td>
            <td style="width: 90px;">
              <label class="switch">
                <input type="checkbox" v-model="item.target_flag" disabled>
                <span class="slider round"></span>
              </label>
            </td>

            <td style="width: 120px;">{{ $t(crawl_val[item.crawl_status]) }}</td>
            <td style="width: 100px;">{{ $t(generate_val[item.generate_status]) }}</td>
            <td style="width: 100px;">{{ $t(check_val[item.check_status]) }}</td>
            <td style="width: 100px;">{{ $t(send_val[item.send_status]) }}</td>

            <td style="width: 250px;">
              <button class="btn btn-primary tabel-btn" data-bs-toggle="modal" data-bs-target="#editSalesListsDetails"
                @click="handleEdit(item.id)">
                {{ $t("SalesListsDetailsView.edit") }}
              </button>
              <button class="btn btn-danger tabel-btn" data-bs-toggle="modal" data-bs-target="#DeleteModal"
                @click="openDeleteModal(item)">
                {{ $t("SalesListsDetailsView.delete") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- ページコンポーネント -->
    <div class="mw-25 pagination-fixed-bottom">
      <nav v-if="showPaging" aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" @click="setFirstPage()">
            <a class="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item" @click="previousPage()"><a class="page-link" href="#">&lt;</a></li>
          <li class="page-item" v-for="(page, index) in activePageList" :key="index">
            <a class="page-link" href="#" :class="page == selectedPage ? 'active' : ''"
              @click="setSelectedPage(page)">{{ page }}</a>
          </li>
          <li class="page-item" @click="nextPage()"><a class="page-link" href="#">&gt;</a></li>
          <li class="page-item" @click="setLastPage()">
            <a class="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Send mail modal begin -->
  <button id="send_mail_finished" data-bs-toggle="modal" data-bs-target="#CheckModal" style="display:none;"></button>
  <div class="modal fade" id="CheckModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="d-modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t('SalesListsDetailsView.send_mail_modal_title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t('SalesListsDetailsView.send_mail_modal_text') }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" @click="refreshData" class="btn btn-primary" data-bs-dismiss="modal">
            {{ $t('SalesListsDetailsView.OK') }}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Send mail modal end -->

  <!-- Delete confirmation modal begin -->
  <div class="modal fade" id="DeleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("SalesListsDetailsView.delete") }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ $t("SalesListsDetailsView.company_name") }}: {{ title_for_delete }}
          {{ $t("SalesListsDetailsView.delete_confirm") }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="handleDelete">
            {{ $t("SalesListsDetailsView.OK") }}
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("SalesListsDetailsView.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete confirmation modal end -->

  <!-- EditSalesListsDetailsView modal begin -->
  <div class="modal fade" id="editSalesListsDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("SalesListsDetailsView.model_title") }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <!--  company_name-->
          <div class="row">
            <label for="company_name" class="form-label">
              {{ $t('SalesListsDetailsView.model_company_name') }} </label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="company_name" v-model="detai_item.company_name">
            </div>
          </div>

          <!--  company_url-->
          <div class="row">
            <label for="company_url" class="form-label">
              {{ $t('SalesListsDetailsView.model_company_url') }} </label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="company_url" v-model="detai_item.company_url">
            </div>
          </div>

          <!--  email-->
          <div class="row">
            <label for="email" class="form-label">
              {{ $t('SalesListsDetailsView.model_email') }} </label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" id="email" v-model="detai_item.email">
            </div>
          </div>

          <!--  company_detail-->
          <div class="row">
            <label for="company_detail" class="form-label">
              {{ $t('SalesListsDetailsView.model_company_detail') }} </label>
            <div class="input-group mb-3">
              <textarea id="company_detail" class="form-control" v-model="detai_item.company_detail"
                rows="8"></textarea>
            </div>
          </div>

          <!--  mail_text-->
          <div class="row">
            <label for="mail_text" class="form-label">
              {{ $t('SalesListsDetailsView.model_mail_text') }} </label>
            <div class="input-group mb-3">
              <textarea id="mail_text" class="form-control" v-model="detai_item.mail_text" rows="20"></textarea>
            </div>
          </div>

          <!--  check_status-->
          <div class="row">
            <div class="column">
              <label for="check_status">
                {{ $t('SalesListsDetailsView.model_check_status') }}
              </label>
              <input class="form-check-input" type="checkbox" id="check_status" v-model="detai_item.check_status" />
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("SalesListsDetailsView.cancel") }}
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="updateData">
            {{ $t("SalesListsDetailsView.model_update") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- EditSalesListsDetailsView modal end -->

</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
}

.table-cell {
  flex: 1;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 40px 0;
}

.table-header .table-cell {
  font-weight: bold;
  background-color: #f5f5f5;
}

.modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  /* 他の要素より前面に */
}

.d-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  /* お好みに合わせて変更 */
  max-width: 500px;
  /* お好みに合わせて変更 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.tabel-btn {
  padding: 0 20px;
  margin: 10px;
}

.fixed-table {
  table-layout: fixed;
  width: 100%;
}

.fixed-table th,
.fixed-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pagination-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  /* 确保在其他元素之上 */
  text-align: center;
  /* 居中对齐 */
}
</style>