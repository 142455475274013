<script setup>
import { onMounted, reactive, computed, ref } from "vue";
import { datePipe } from "@/utils/datatimeUtils";
import { spliceTags } from "@/utils/tagUtils";
import { useRoute, useRouter } from "vue-router";
import { resourceApi, chatsApi } from "@/api";
import TagsInput from "@/components/TagsInput.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

// get router instance
const router = useRouter();
const route = useRoute();
const chatId = route.params.chat_id;

// data
const data = reactive({ resources: [] });
const filterKey = reactive({
  searchKey: null,
  tagFilter: [],
});
const searchKey = ref("");
const tagInput = ref(null);
const isLoading = ref(false);
const ws = ref(null);
const errorMessage = ref("");
const showModal = ref(false);
const proccesingResourceId = ref("");
const proccesingResourceTitle = ref("");
const showDeleteModal = ref(false);

// computed
const resourceCount = computed(() => {
  return filteredData.value.length;
});

const createWebSocketConnection = async () => {
  const wsId = Math.random().toString(32).substring(2)
  ws.value = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`);

  ws.value.onmessage = (event) => {
    const responseData = JSON.parse(event.data);
    if (responseData.status === "OK") {
      ws.value.close();
      isLoading.value = false;
    } else if (responseData.status === "error") {
      ws.value.close();
      isLoading.value = false;
      errorMessage.value = "申し訳ありませんが、削除中に問題が発生しました。しばらくしてからもう一度試してください。"
      showModal.value = true;
    } else {
      ws.value.close();
      isLoading.value = false;
      errorMessage.value = "申し訳ありませんが、削除中に問題が発生しました。しばらくしてからもう一度試してください。"
      showModal.value = true;
    }
    data.resources = deleteResourceById(proccesingResourceId.value, data.resources);
    proccesingResourceId.value = "";
    proccesingResourceTitle.value = "";
  };

  return wsId;
};

const wordCount = computed(() => {
  if (filteredData.value.length === 0) {
    return 0;
  } else {
    return filteredData.value
      .map((resource) => resource.text.length)
      .reduce((prev, curr) => prev + curr);
  }
});

const filteredData = computed(() => {
  let temp = data.resources;
  if (filterKey.searchKey) {
    temp = temp.filter((row) =>
      Object.keys(row).some(
        (key) =>
          String(row[key]).toLowerCase().indexOf(filterKey.searchKey.toLowerCase()) > -1
      )
    );
  }
  if (filterKey.tagFilter.length > 0) {
    temp = temp.filter((row) =>
      row.resource_tags.some(
        (tag) => filterKey.tagFilter.indexOf(tag.tag.toLowerCase()) > -1
      )
    );
  }
  return temp;
});

// lifecycle hooks
onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await chatsApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push(`/admin_error`);
    }
  } catch (error) {
    return router.push(`/admin_error`);
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }

  const resp = await resourceApi.getAll({ chat_id: chatId });
  data.resources = resp.data;
});

// handlers
const handleDelete = async () => {
  isLoading.value = true;
  const wsId = await createWebSocketConnection();
  const resp = await resourceApi.delete(proccesingResourceId.value, { chat_id: chatId }, wsId);
  if (resp.status != 200) {
    isLoading.value = false;
    ws.value.close();
    throw new Error("ERROR");
  }
  closeDeleteModal();
};

const handleEdit = (resourceId) => {
  router.push(`/chats/${chatId}/resource/${resourceId}`);
};

const handleFilter = () => {
  filterKey.searchKey = searchKey.value;
  filterKey.tagFilter = tagInput.value.selected.map((tag) => tag.toLowerCase());
};

const closeModal = () => {
  showModal.value = false;
};

// Show the delete confirmation modal
const openDeleteModal = (resource) => {
  proccesingResourceId.value = resource.id;
  proccesingResourceTitle.value = resource.resource_name;
  showDeleteModal.value = true;
};

// Close the delete confirmation modal
const closeDeleteModal = () => {
  showDeleteModal.value = false;
};

// helpers
/**
 *
 * @param {Number} id
 * @param {Array} resources
 */
const deleteResourceById = (id, resources) => {
  return resources.filter((resource) => resource.id !== id);
};
</script>

<template>
  <!-- confirmation modal -->
  <div v-if="showModal" class="modal">
    <div class="d-modal-content">
      <p>{{ errorMessage }}</p>
      <button class="btn btn-primary" @click="closeModal">{{ $t('ChatEditResourceView.close') }}</button>
    </div>
  </div>
  <!-- Delete confirmation modal -->
  <div v-if="showDeleteModal" class="modal-delete">
    <div class="d-modal-content">
      <p>リソース: {{ proccesingResourceTitle }} を削除しますか？</p>
      <button class="btn btn-primary" @click="handleDelete">はい</button>
      <button class="btn btn-primary" @click="closeDeleteModal">キャンセル</button>
    </div>
  </div>
  <div class="container">
    <!-- ローディング -->
    <LoadingIcon :is-loading="isLoading"></LoadingIcon>
    <h4>{{ $t('ChatResourceView.h4') }}</h4>
    <br>
    <div class="mb-3">
      <label for="search-keyword" class="form-label">{{ $t('ChatResourceView.keyword') }}</label>
      <input type="text" class="form-control" id="search-keyword" v-model="searchKey">
    </div>
    <div class="mb-3">
      <label for="search-tags" class="form-label">{{ $t('ChatResourceView.tags') }}</label>
      <TagsInput :scope="false" ref="tagInput" id="search-tags" style="display: inline-block"></TagsInput>
    </div>
    <div>
      {{ $t('ChatResourceView.total_number_of_characters') }} <span id="word-count">{{ wordCount }}</span>
    </div>
    <br>
    <div>
      {{ $t('ChatResourceView.resource_count') }} <span id="resource-count">{{ resourceCount }}</span>
    </div>

    <div style="text-align: right">
      <button class="btn btn-primary" @click="handleFilter" style="padding: 0 50px">
        {{ $t('ChatResourceView.filter') }}
      </button>
    </div>
    <br>

    <!-- 登録済みリソース一覧テーブル -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">{{ $t('ChatResourceView.resource_name') }}</th>
          <th scope="col">{{ $t('ChatResourceView.file_type') }}</th>
          <th scope="col">{{ $t('ChatResourceView.tags') }}</th>
          <th scope="col">URL</th>
          <th scope="col">{{ $t('ChatResourceView.created_at') }}</th>
          <th scope="col">{{ $t('ChatResourceView.status') }}</th>
          <th scope="col">{{ $t('ChatResourceView.operation') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="resource in filteredData" :key="resource.id">
          <td>{{ resource.resource_name }}</td>
          <td>{{ resource.file_type.toUpperCase() }}</td>
          <td>{{ spliceTags(resource.resources_tags) }}</td>
          <td>{{ resource.document_url }}</td>
          <td>{{ datePipe(resource.created_at) }}</td>
          <td v-if="resource.status === 0">{{ $t('ChatResourceView.status_zero') }}</td>
          <td v-else-if="resource.status === 1">{{ $t('ChatResourceView.status_one') }}</td>
          <td v-else-if="resource.status === 2">{{ $t('ChatResourceView.status_two') }}</td>
          <td v-else-if="resource.status === 3">{{ $t('ChatResourceView.status_three') }}</td>
          <td v-else>-</td>
          <td>
            <button class="btn btn-primary btn-sm table-btn" @click="handleEdit(resource.id)">
              {{ $t('ChatResourceView.edit') }}
            </button>
            <button class="btn btn-danger btn-sm" @click="openDeleteModal(resource)">
              {{ $t('ChatResourceView.delete') }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <div class="row">
      <h4>登録済みリソース一覧</h4>
    </div>
    <div class="row">
      <div class="column">
        <label for="serach-keyword">キーワード：</label>
        <input type="text" id="search-keyword" v-model="searchKey" />
      </div>
      <div class="column">
        <label for="search-tags">タグ：</label>
        <TagsInput
          :scope="false"
          ref="tagInput"
          id="search-tags"
          style="display: inline-block"
        ></TagsInput>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <label for="word-count">文字数数合計：</label>
        <span id="word-count">{{ wordCount }}</span>
      </div>
      <div class="column">
        <label for="resource-count">リソース数：</label>
        <span id="resource-count">{{ resourceCount }}</span>
      </div>
      <div class="column" style="text-align: right">
        <button @click="handleFilter" style="padding: 0 50px">絞り込み</button>
      </div>
    </div> -->
    <!-- <div class="table-frame">
      <table>
        <thead>
          <tr>
            <th>リソース名</th>
            <th>ファイル形式</th>
            <th>タグ</th>
            <th>URL</th>
            <th>登録日時</th>
            <th>ステータス</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in filteredData" :key="resource.id">
            <td>{{ resource.resource_name }}</td>
            <td>{{ resource.file_type.toUpperCase() }}</td>
            <td>{{ spliceTags(resource.resource_tags) }}</td>
            <td>{{ resource.document_url }}</td>
            <td>{{ datePipe(resource.created_at) }}</td>
            <td>{{ resource.status }}</td>
            <td>
              <button @click="handleEdit(resource.id)">編集</button>
              <button @click="handleDelete(resource.id)">削除</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </div>
</template>

<style scoped>
/* .row {
  display: flex;
  margin: 15px 5px;
}

.column {
  flex: 1;
} */

.table-btn {
  margin-right: 8px
}

.modal-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  /* 他の要素より前面に */
}
</style>
