<script>
import { useRoute } from "vue-router";
import { chatApi, chatsApi, talksApi } from "@/api";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { ChatSet, ChatMeta } from "@/store/modules/chat";
import { TalksReferences, References } from "@/store/modules/talks";

export default {
  components: {
    LoadingIcon,
  },
  data() {
    const route = useRoute();
    const chatId = parseInt(route.params.chat_id);
    return {
      chatId,
      history_chat: [],
      chatSet: new ChatSet(),
      show_messages: false,
      modalTitle: "",
      metaModalText: "",
      resourceId: "",
      userMessage: "",
      filterVisible: false, // フィルターセクションの表示・非表示制御
      isFilterSectionVisible: true,
      isLoading: false,
      isMetaModalVisible: false,
      showModal: false,
      tags: [],
      file_name: "",
      keyDownCode: 0,
      input_msg: this.$t('ChatScreenView.input_msg'),
      errorMessage: this.$t('ChatScreenView.error_messages'),
      close: this.$t('ChatScreenView.close'),
      showTemplate: true,
      chatQuestionTemplateList: [],
      talkList: [],
      showTalkList: true,
      selectedTalkId: 0,
      page: 2,
      is_bottom: false,
      newtalkTitle: "New Talk",
      selectedTalkTitle: "New Talk",
      ws: undefined,
      timer: undefined,
    };
  },
  created: function () {
    return this.$store.dispatch("viewMe");
  },
  computed: {
    history_chats() {
      const chatId = this.chatId;
      console.log(this.$store.getters.getChatById(chatId)?.chatSets || [])
      return this.$store.getters.getChatById(chatId)?.chatSets || [];
    }
  },
  async mounted() {
    // The DOM element you wish to replace with Tagify
    // var input = document.querySelector('input[name=tags]');
    // initialize Tagify on the above input node reference
    // new Tagify(input)
    await this.$store.dispatch("createChatIfNotExist", this.chatId)
    this.setTemplate();
    this.setTalkList();
    this.clearMessages();
  },
  beforeUnmount() {
    if (this.tagify) {
      this.tagify.destroy();
    }
    if (this.ws) {
      this.ws.close();
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.errorMessage = "";
    },
    keyDownEnter(e) {
      if (e.shiftKey) {
        // Shiftキーが同時に押されている場合は、デフォルトの動作（改行）を許可
        return;
      }
      this.keyDownCode = e.keyCode; // enterを押した時のkeycodeを記録
      e.preventDefault();
    },
    keyUpEnter(e) {
      if (this.keyDownCode === 229) { // 229コードの場合は処理をストップ
        return;
      }
      e.preventDefault();
      this.sendMessage();
    },
    keyEnterShift() {
      // shift+enterの場合はデフォルトの動作を許可して改行する
      console.log('shift,enter')
    },
    async createWebSocketConnection() {
      const wsId = Math.random().toString(32).substring(2)
      const ws = new WebSocket(`${process.env.VUE_APP_WEBSOCKET_URL}/ws/${wsId}`);
      ws.onmessage = (event) => {
        (async () => {
          try {
            console.log(event);    
            const wsResponseData = JSON.parse(event.data);
            console.log(wsResponseData);
            if (wsResponseData.error) {
              this.isLoading = false;
              this.showModal = true;
              this.show_messages = false;
            } else {
              clearTimeout(this.timer);
              await this.processResponse(wsResponseData);
            }
          } catch (error) {  
            console.error('Error processing WebSocket message:', error);
            // 必要に応じてエラーハンドリングをここに追加
            this.isLoading = false;
            this.showModal = true;
            this.show_messages = false;   
          } finally {
            clearTimeout(this.timer);
            ws.close();
          }
        })();
      };

      return wsId;
    },
    async processResponse(responseData) {
      // ローディングを終了
      this.isLoading = false;

      //参照リソース表示
      const res_query_results = responseData.query_results[0].results;
      // res_metadatasを整形する   
      for (const result_row of res_query_results) {
        let res_chat_set_meta = new ChatMeta();
        res_chat_set_meta.file_name = result_row.metadata.file_name;
        res_chat_set_meta.document_url = result_row.metadata.document_url;
        res_chat_set_meta.tags = `タグ：${result_row.metadata.tags}`;
        res_chat_set_meta.resource_id = result_row.metadata.resource_id;
        res_chat_set_meta.text = result_row.text;
        this.chatSet.meta.push(res_chat_set_meta);
      }

      //ストリームリクエスト
      const msgResponse = await chatApi.chat_stream(
        responseData.chunks, 
        this.userMessage, 
        this.chatId,
        this.selectedTalkId
      )
      
      this.chatSet.question = this.userMessage;
      this.chatSet.messages = [];
      this.show_messages = true;

      let streamResponseData = {};
      try {
        streamResponseData = msgResponse.body.getReader();
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.showModal = true;
        this.show_messages = false;
        return
      }

      // ストリームレスポンスのパース処理
      let result = true;
      let chat_line = "";
      let i = 0;
      this.chatSet.messages.push("");
      while (result) {
        const { done, value } = await streamResponseData.read();
        console.log(value);
        this.show_messages = true;
        if (done) {
          console.log('Stream ended');
          result = false;
          this.show_messages = false;
          break;
        }

        let result_string = new TextDecoder().decode(value);
        if (result_string.includes('\n')) {
          let n_lsit = result_string.replace("\n\n", "\n");
          n_lsit = n_lsit.split('\n');
          chat_line += n_lsit[0];
          this.chatSet.messages[i] = chat_line;
          chat_line = n_lsit[1];
          this.chatSet.messages.push("");
          i += 1;
        } else {
          chat_line += result_string;
          this.chatSet.messages[i] = chat_line;
        }
      }
      // Chatインスタンスに格納
      const chatSet = new ChatSet();
      chatSet.messages = this.chatSet.messages;
      chatSet.question = this.chatSet.question;
      chatSet.meta = this.chatSet.meta;

      // メッセージ履歴storeにpush
      console.log(chatSet)
      try {
        this.$store.commit("updateChatMessages", { chatSet: chatSet, chatId: this.chatId });
      } catch (error) {
        console.log(error);
      }

      // 会話履歴保存
      await this.seveConversations(this.chatSet);

      // チャット情報収納変数初期化
      //this.chatSet = new ChatSet();
      this.chatSet.meta = [];
      this.userMessage = "";
      this.filterVisible = false;

      //  DOM適用が完了したら一番下までスクロール
      this.$nextTick(() => {
        const container = this.$refs.messageHistory;
        container.scrollTop = container.scrollHeight;
      });
    },
    async sendMessage() {
      console.log('senMessage');
      //入力がない場合は実行しない
      if (this.userMessage === "") {
        return
      }
      // 30秒後にshowTimeOutModalを実行する
      this.timer = setTimeout(this.showTimeOutModal, 30000);

      const wsId = await this.createWebSocketConnection();
      
      // query request (request vectordb, return chunks)
      // metadata
      const metadata = {};

      // ローディング開始
      this.isLoading = true;

      const queries = [{ query: this.userMessage, top_k: 3, filter: metadata }]
      try {
        const response = await chatApi.chat_reference(queries, this.userMessage, this.chatId, wsId)
        if (response.status != 200) {
          throw new Error("ERROR");
        }
      } catch (error) {
        this.isLoading = false;
        this.showModal = true;
      }
    },

    setMetaMsg(resource_id, modalTitle, metaModalText) {
      this.resourceId = resource_id;
      this.modalTitle = modalTitle
      this.metaModalText = metaModalText
      this.isMetaModalVisible = true;
    },

    toggleFilterSection() {
      this.isFilterSectionVisible = !this.isFilterSectionVisible;
    },

    // モーダルを表示するメソッド
    showMetaModal() {
      this.isMetaModalVisible = true;
    },

    // モーダルを非表示にするメソッド
    closeMetaModal() {
      this.isMetaModalVisiblee = false;
    },

    clearMessages() {
      this.messages = [],
        this.$store.commit("clearMessages", this.chatId)
    },

    async setTemplate() {
      await chatsApi.getTemplateById(this.chatId).then(response => {
        response.data.forEach(ChatQuestionTemplate => {
          this.chatQuestionTemplateList.push(ChatQuestionTemplate);
        });
      }).catch(() => {
        this.showTemplate = false;
      });
    },

    sendTemplate(question_text) {
      this.userMessage = question_text;
      // this.sendMessage();
    },

    showHistoryBar() {
      this.showTalkList = !this.showTalkList;
    },

    async handleScroll(event) {
      // 获取滚动容器的高度、滚动高度和视口高度
      // スクロールコンテナの高さ、スクロールの高さ、ビューポートの高さを取得する
      const container = event.target;
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight;

      // 判断是否滚动到底部
      // 最下部までスクロールするかどうかを判断する
      if (scrollHeight - scrollTop === clientHeight) {
        // 处理滚动到底部的逻辑
        // 最下部までの場合
        console.log('Scrolled to the bottom!');
        this.is_bottom = true;
        try {
          const res = await talksApi.getTalks(this.chatId, this.page);
          if (res.data.length) {
            this.talkList.push.apply(this.talkList, res.data);
            this.page++;
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.is_bottom = false;
        }
      }
    },

    async setTalkList() {
      try {
        const res = await talksApi.getTalks(this.chatId, 1);
        if (res.data.length) {
          this.talkList = res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    getTimeString(dateString) {
      let date = new Date(dateString)
      var y = date.getFullYear();
      var M = date.getMonth() + 1;
      var d = date.getDate();
      var H = date.getHours();
      var m = date.getMinutes();
      var s = date.getSeconds();
      return y + '-' + (M < 10 ? ('0' + M) : M) + '-'
        + (d < 10 ? ('0' + d) : d) + " " + (H < 10 ? ('0' + H) : H)
        + ":" + (m < 10 ? ('0' + m) : m) + ":" + (s < 10 ? ('0' + s) : s);
    },

    async createNewTalk(title) {
      try {
        this.selectedTalkTitle = title;
        if (this.selectedTalkId != 0) {
          this.clearMessages();
        }
        const res = await talksApi.createTalk(this.chatId, title);
        this.talkList.unshift(res.data);
        this.selectedTalkId = res.data.id;
      } catch (error) {
        console.error(error);
      }
    },

    async getConversations(talkId, index) {
      this.selectedTalkTitle = this.talkList[index].talk_title;
      this.showModal = true;
      this.clearMessages();
      this.selectedTalkId = talkId;
      this.history_chat = [];
      this.chatSet = new ChatSet();
      try {
        const res = await talksApi.getConversations(talkId);
        if (res.data.length) {
          res.data.forEach((item) => {
            const chatSet = new ChatSet();
            chatSet.question = item.request_message;
            chatSet.messages = item.response_message.split("\n");
            chatSet.meta = item.talk_references.map((data) => {
              const meta = new ChatMeta()
              meta.document_url = data.document_url;
              meta.file_name = data.file_name;
              meta.resource_id = data.resource_id;
              meta.text = data.text;
              return meta
            });
            this.$store.commit("updateChatMessages", { chatSet: chatSet, chatId: this.chatId });
          });
          this.history_chat.push(res.data);
        }
        this.showModal = false;
      } catch (error) {
        console.error(error);
      }
    },

    async seveConversations(chatSet) {
      const title = chatSet.question.substring(0, 51);
      if (this.selectedTalkId == 0) {
        await this.createNewTalk(title);
      }

      let conversations = new TalksReferences();
      conversations.chat_id = this.chatId;
      conversations.talk_id = this.selectedTalkId;
      conversations.request_message = chatSet.question;
      conversations.response_message = chatSet.messages.join("\n");
      conversations.references = chatSet.meta.map((item) => {
        let references = new References();
        references.document_url = item.document_url;
        references.file_name = item.file_name;
        references.resource_id = item.resource_id;
        references.text = item.text;
        return references
      });

      try {
        await talksApi.createConversations(conversations);
        if (this.selectedTalkId != 0 && this.talkList[0].talk_title == "New Talk") {
          this.selectedTalkTitle = title;
          this.talkList[0].talk_title = title;
        }
      } catch (error) {
        console.error(error);
      }
    },

    makeTitle(title) {
      if (title.length > 20) {
        return title.substring(0, 20) + "..."
      }
      return title
    },

    showTimeOutModal() {
      this.isLoading = false;
      if (this.ws) { this.ws.close(); }
      let btn = document.getElementById("btnTimeOut");
      btn.click();
    },
  },
};

</script>

<template>
  <div class="chat-app">
    <!-- ローディング -->
    <LoadingIcon :is-loading="isLoading"></LoadingIcon>
    <!-- チャット履歴 -->
    <div class="container px-4">
      <div class="row gx-3">
        <div v-if="showTalkList" class="col-3">
          <div class="p-3 border bg-light rounded-3 h-100">
            <button type="button" class="btn-close mb-3 shadow" aria-label="Close" @click="showHistoryBar"></button>
            <button type="button" class="btn btn-outline-secondary w-100 mb-3 shadow"
              @click="createNewTalk(newtalkTitle)">
              {{ $t('ChatScreenView.new_talk') }}
            </button>
            <div class="his-list-left" @scroll="handleScroll">
              <ul class="px-0">
                <div class="card shadow his-card-h mb-3 w-95 card-animation" v-for="(talk, index) in talkList"
                  :key="talk.id">
                  <div class="card-body font-size" @click="getConversations(talk.id, index)">
                    <div>{{ makeTitle(talk.talk_title) }}</div>
                    <div class="pe-3 mb-3">{{ getTimeString(talk.created_at) }}</div>
                  </div>
                </div>
              </ul>
              <div v-if="is_bottom" class="d-flex justify-content-center w-95">
                <div class="spinner-border text-secondary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="p-3 border bg-light rounded-3">
            <!-- ツールバー -->
            <h4>{{ makeTitle(selectedTalkTitle) }}</h4>
            <div class="d-flex flex-row-reverse bd-highlight">
              <button type="button" class="btn btn-primary m-3 shadow" @click="showHistoryBar()">
                {{ $t('ChatScreenView.his_talk') }}
              </button>
            </div>
            <!-- チャット -->
            <div class="card text-bg-light mb-3 bg-white border border-white card_h shadow" ref="messageHistory">
              <div class="card-body bg-white border border-white card-scroll">
                <div v-for="(chatSet, index) in history_chats" :key="index">
                  <div class="alert  alert-info message user-message ms-auto">{{ chatSet.question }}</div>
                  <div class="alert alert-secondary message chatgpt-message">
                    <span>
                      <div v-for="(line, index) in chatSet.messages" :key="index">
                        <p>{{ line }}</p>
                      </div>
                    </span>
                  </div>
                  <div class="d-flex flex-row flex-wrap">
                    <div v-for="(meta, index) in chatSet.meta" :key="index" class="metaBtn">
                      <div class="btn-group color chatgpt-message btn-group-sm rounded-pill" role="group">
                        <button type="button" class="btn rounded-pill"
                          @click="setMetaMsg(meta.resource_id, meta.file_name, meta.text)" data-bs-toggle="modal"
                          data-bs-target="#meta"
                          style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                          {{ meta.file_name }}
                        </button>
                        <a class="btn btn-link rounded-pill" v-if="meta.document_url"
                          style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                          :href="meta.document_url" target="_blank">{{ $t('ChatScreenView.link') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="show_messages">
                  <div class="alert alert-info message user-message ms-auto">{{ chatSet.question }}</div>
                  <div class="alert  alert-secondary message chatgpt-message">
                    <span>
                      <div v-for="(line, index) in chatSet.messages" :key="index">
                        <p>{{ line }}</p>
                      </div>
                    </span>
                  </div>
                  <div class="d-flex flex-row flex-wrap">
                    <div v-for="(meta, index) in chatSet.meta" :key="index" class="metaBtn">
                      <div class="btn-group color chatgpt-message btn-group-sm rounded-pill" role="group">
                        <button type="button" class="btn rounded-pill"
                          @click="setMetaMsg(meta.id, meta.file_name, meta.text)" data-bs-toggle="modal"
                          data-bs-target="#meta"
                          style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
                          {{ meta.file_name }}
                        </button>
                        <a class="btn btn-link rounded-pill" v-if="meta.document_url"
                          style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                          :href="meta.document_url" target="_blank">{{ $t('ChatScreenView.link') }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div v-if="showTemplate" class="d-flex flex-column">
                  <div class="ms-auto mb-3">{{ $t('ChatScreenView.template_message') }}</div>
                  <div v-for="(template, index) in chatQuestionTemplateList" :key="index"
                    class="d-flex align-items-end flex-column mb-3">
                    <button type="button" class="btn btn-outline-secondary shadow-sm rounded"
                      @click="sendTemplate(template.question_text)">
                      {{ template.question_title }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-white border border-white">
                <br>
                <!-- インプットフォーム -->
                <div class="input-group mb-3 shadow-sm rounded shadow">
                  <textarea class="form-control border-secondary" rows="3" v-model="userMessage"
                    :placeholder="$t('ChatScreenView.input_msg')" aria-describedby="button-send"
                    @keydown.enter.exact="keyDownEnter" @keyup.enter.exact="keyUpEnter"
                    @keydown.enter.shift="keyEnterShift" :disabled="show_messages"></textarea>
                  <button class="btn btn-outline-secondary" type="button" id="button-send" @click="sendMessage"
                    :disabled="show_messages">Send</button>
                </div>
              </div>
              <!-- エラーモーダル -->
              <div v-if="showModal" class="modal-error">
                <div class="modal-content">
                  <p>{{ errorMessage }}</p>
                  <button class="btn btn-primary" @click="closeModal">{{ $t('ChatScreenView.close') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- 参考文章モーダル  -->
    <div class="modal fade" v-show="isMetaModalVisible" id="meta" tabindex="-1" data-bs-backdrop="static"
      data-bs-keyboard="false" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <a v-if="resourceId" :href="`/chats/${this.chatId}/resource/${resourceId}`" target="_blank">
              {{ modalTitle }}
            </a>
            <span v-else>{{ modalTitle }}</span>
          </div>
          <div class="modal-body">
            {{ metaModalText }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Button trigger modal -->
    <button type="button" class="btn btn-primary invisible" id="btnTimeOut" data-bs-toggle="modal"
      data-bs-target="#timeOutModal">
    </button>

    <!-- Time Out Modal -->
    <div class="modal fade" id="timeOutModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      data-bs-backdrop="static" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Time Out</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ $t('ChatScreenView.error_messages') }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.message {
  width: fit-content;
  max-width: 80%;
}

.card_h {
  height: 70vh;
}

.card-scroll {
  overflow: auto;
}

.color {
  background-color: #D5BDFF;
}

.metaBtn {
  margin-right: 15px;
}

@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
    z-index: -1;
  }

  to {
    opacity: 1;
  }
}

.his-card-h {
  height: 100px;
}

.font-size {
  font-size: 15px;
}

.his-list-left {
  overflow-x: hidden;
  overflow-y: auto;
  height: 70vh;
}

.w-95 {
  width: 95%;
}

.card-animation:hover {
  cursor: pointer;
  color: white;
  background-color: gray;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar:hover {
  cursor: default;
}


::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
</style>